export const ladies = [
  {
    label: "Extra Short Wash, Cut & Style",
    value: 195,
  },
  {
    label: "Short Wash, Cut & Blow Wave",
    value: 195,
  },
  {
    label: "Medium Wash, Cut & Blow Wave",
    value: 220,
  },
  {
    label: "Long Wash, Cut & Blow Wave",
    value: 225,
  },
  {
    label: "Extra Long Wash, Cut & Blow Wave",
    value: 280,
  },
  {
    label: "Short Wash & Blow Wave",
    value: 160,
  },
  {
    label: "Medium Wash & Blow Wave",
    value: 185,
  },
  {
    label: "Long Wash & Blow Wave",
    value: 190,
  },
  {
    label: "Extra Long Wash & Blow Wave",
    value: 240,
  },
];

export const gents = [
  {
    label: "Cut & Style",
    value: 115,
  },
  {
    label: "Cut & Colour",
    value: 230,
  },
  {
    label: "Cut & Highlight",
    value: 290,
  },
];

export const kids = [
  {
    label: "Girls Cut & Blow Wave",
    value: 150,
  },
  {
    label: "Boys Cut & Style",
    value: 90,
  },
];

export const colour = [
  {
    label: "T Section",
    value: 590,
  },
  {
    label: "Short Colour",
    value: 1280,
  },
  {
    label: "Medium Colour",
    value: 1660,
  },
  {
    label: "Long Colour",
    value: 2200,
  },
  {
    label: "Extra Long Colour",
    value: 2410,
  },
  {
    label: "Roots 2.5cm Or Less",
    value: 960,
  },
];

export const traditionalHighlights = [
  {
    label: "Short Highlights",
    value: 970,
  },
  {
    label: "Medium Highlights",
    value: 1170,
  },
  {
    label: "Long Highlights",
    value: 1320,
  },
  {
    label: "Extra Long Highlights",
    value: 1540,
  },
];

export const advancedLightning = [
  {
    label: "Medium",
    value: 1260,
  },
  {
    label: "Long",
    value: 1410,
  },
  {
    label: "Extra Long",
    value: 1620,
  },
  {
    label: "Money Piece",
    value: 680,
  },
];

export const toner = [
  {
    label: "Short Toner",
    value: 240,
  },
  {
    label: "Medium Toner",
    value: 270,
  },
  {
    label: "Long Toner",
    value: 310,
  },
  {
    label: "Extra Long Toner",
    value: 340,
  },
  {
    label: "Money Piece Toner",
    value: 50,
  },
];

export const bcBlondeMeMask = [
  {
    label: "Short Mask",
    value: 150,
  },
  {
    label: "Medium Mask",
    value: 190,
  },
  {
    label: "Long Mask",
    value: 220,
  },
  {
    label: "Extra Long Mask",
    value: 250,
  },
];

export const blondeMeVitaCShotMask = [
  {
    label: "Short Vita C",
    value: 300,
  },
  {
    label: "Medium Vita C",
    value: 300,
  },
  {
    label: "Long Vita C",
    value: 530,
  },
  {
    label: "Extra Long Vita C",
    value: 530,
  },
];
